import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Calendar = () => {
  const [recentEvents, setRecentEvents] = useState([]);

  useEffect(() => {
    fetch('https://alpine-larochelle.com/api/news')
      .then((response) => response.json())
      .then((data) => {
        const eventsWithDates = data
          .filter(news => news.date)
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);
        setRecentEvents(eventsWithDates);
      })
      .catch((err) => console.log('Error loading events:', err));
  }, []);

  return (
    <section className="calendar">
        <div className="calendar__content__left">
            <h2>Les derniers événements à ne pas manquer</h2>
        </div>
        <div className="calendar__content__right">
            <ul className="calendar__events">
                {recentEvents.map(event => (
                <li key={event._id} className="calendar__event">
                    <div className="event-date">
                    {event.date && new Date(event.date).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    })}
                    </div>
                    <Link to={`/news/${event._id}`} className="event-title">
                    {event.title}
                    </Link>
                </li>
                ))}
            </ul>
        </div>
    </section>
  );
};

export default Calendar;