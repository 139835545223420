import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Button from '../components/Bouton';

export default function SingleNews() {
  const { id } = useParams();
  const [news, setNews] = useState(null);

  useEffect(() => {
    fetch(`https://alpine-larochelle.com/api/news/${id}`)
      .then((response) => response.json())
      .then((data) => setNews(data))
      .catch((err) => console.log('Error loading news:', err));
  }, [id]);

  if (!news) return <div>Chargement...</div>;

  return (
    <main className="single-news">
      <div className="single-news__container">
        <div className="single-news__header">
          <h1>{news.title}</h1>
          {news.date && (
            <p className="single-news__date">
              {new Date(news.date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </p>
          )}
        </div>
        <div className="single-news__image">
          <img src={`https://alpine-larochelle.com/uploads/${news.image}`} alt={news.title} />
        </div>
        <div className="single-news__content">
          <div dangerouslySetInnerHTML={{ __html: news.text }} className="news-text" />
          <div className="single-news__buttons">
            <Button Text="Lien externe" Link={news.link} />
            <Button Text="Retour aux actualités" Link="/news" />
          </div>
        </div>
      </div>
    </main>
  );
};