import { useState, useEffect } from 'react';
import Car from '../components/Car';

export default function Cars() {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function loadVehicles() {
            try {
                const apiUrl = process.env.NODE_ENV === 'production'
                    ? '/api/vehicles/alpine'  // En production
                    : 'http://localhost:5000/api/vehicles/alpine';  // En développement
                
                console.log('Fetching from:', apiUrl);
                const response = await fetch(apiUrl);
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();
                setVehicles(data);
            } catch (error) {
                console.error('Erreur détaillée:', error);
                setError('Impossible de charger les véhicules');
            } finally {
                setLoading(false);
            }
        }

        loadVehicles();
    }, []);

    if (loading) return <div className="loading">Chargement...</div>;
    if (error) return <div className="error">{error}</div>;
    if (vehicles.length === 0) return <div>Aucun véhicule disponible</div>;

    return (
        <div className="cars-grid">
            {vehicles.map((vehicle, index) => (
                <Car key={index} {...vehicle} />
            ))}
        </div>
    );
} 