import "../styles/main.scss"
import React from 'react';
import Home from '../pages/Home';
import Footer from "../components/Footer";
import Admin from "./Admin";
import News from "../pages/News";
import Nav from "../components/Nav";
import SingleNews from "../pages/SingleNews";
import { Routes, Route, useLocation } from 'react-router-dom';
import Cars from "./Cars";

export default function App() {
  const location = useLocation();
  
    return (
      <>
        {location.pathname !== "/" && <Nav />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/SecretAdmin" element={<Admin />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<SingleNews />} />
          <Route path="/cars" element={<Cars />} />
        </Routes>
        <Footer />
      </>
    );
  }