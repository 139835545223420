import "../styles/main.scss"
import React from 'react';
import Nav from "../components/Nav";
import Carrousel from "../components/Carrousel";
import alpineLogoLettres from "../assets/alpine-logo-lettres.png";
import NewsCarrousel from "../components/NewsCarrousel";
import Calendar from "../components/Calendar";

export default function Home() {
    return (
        <>
            <header>
                <Nav />
                <div className="alpine-logo--la-rochelle">
                    <img src={alpineLogoLettres} alt="Logo Alpine Blanc"></img>
                    <h1>Store La Rochelle</h1>
                </div>
            </header>
            <main>
                <Carrousel />
                <Calendar />
                <NewsCarrousel />
            </main>
        </>
    );
}