import React from "react";
import alpineLogoNav from "../assets/alpine-logo-blanc.png";
import { NavLink } from 'react-router-dom';

export default function Nav() {
    return (
        <nav>
            <NavLink to="/" className="alpine-logo--nav"><img src={alpineLogoNav} alt="Logo Blanc Alpine" ></img></NavLink>
            <a href="https://www.alpinecars.fr/">Modèles</a>
            <NavLink to="/cars">Nos Véhicules</NavLink>
            <a href="https://www.alpinecars.fr/gamme/a110/configurer.html">Configurateur</a>
            <NavLink to="/news">Actualités</NavLink>
            <a href="https://www.alpinecars.fr/contactez-nous/reservez-un-essai/book-a-test-drive/contact-details.html?modelAdminId=a290">Contact</a>
        </nav>
    );
}