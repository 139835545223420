import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
    const { Link: link, Text, onClick, type } = props;
    
    // Si c'est un bouton de type submit ou avec onClick
    if (type === 'submit' || onClick) {
        return (
            <button 
                className="button" 
                onClick={onClick}
                type={type || 'button'}
            >
                <span>{Text}</span>
            </button>
        );
    }

    // Si le lien commence par '/', c'est un lien interne
    const isInternalLink = link.startsWith('/');

    return isInternalLink ? (
        <Link className="button" to={link}>
            <span>{Text}</span>
        </Link>
    ) : (
        <a className="button" href={link}>
            <span>{Text}</span>
        </a>
    );
}