import React from "react";
import FacebookLogo from "../assets/facebook-f-brands-solid 1.png";
import InstagramLogo from "../assets/instagram-brands-solid 2.png";
import LinkedInLogo from "../assets/linkedin-in-brands-solid 1.png";
import GMLogo from "../assets/logo-gm-blanc.png";

export default function Footer() {
    return (
        <footer>
            <div className="footer__links">
                <a href="https://www.alpinecars.fr/contactez-nous/reservez-un-essai/book-a-test-drive/contact-details.html?modelAdminId=a290">
                    <li>Nous contacter</li>
                </a>
                <li>Cookies</li>
                <li>Mentions Légales</li>
                <a href="https://www.alpinecars.fr/">
                    <li>Alpinecars.com</li>
                </a>
            </div>
            <div className="footer__socials">
                <a href="https://www.facebook.com/AlpineGroupeMichel/?locale=fr_FR"><img src={FacebookLogo}></img></a>
                <a href="https://www.instagram.com/alpine_larochelle/"><img src={InstagramLogo}></img></a>
                <a href="https://www.linkedin.com/showcase/alpine-store-la-rochelle/?originalSubdomain=fr"><img src={LinkedInLogo}></img></a>
            </div>
            <a href="https://www.groupemichel.com/">
                <img className="footer__gm-logo" src={GMLogo}></img>
            </a>
        </footer>
    );
}