import React, { useState, useEffect } from "react";
import Button from "../components/Bouton";

export default function News() {
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        fetch('https://alpine-larochelle.com/api/news')
          .then((response) => response.json())
          .then((data) => setNewsList(data))
          .catch((err) => console.log('Error loading news:', err));
      }, []);

    return (
        <main className="news">
            <h2>Toutes les actualités</h2>
            <div className="news-list">
                {newsList.map((news) => (
                    <div className="news-single" key={news._id}>
                        <img src={`https://alpine-larochelle.com/uploads/${news.image}`} alt={news.title}></img>
                        <div className="news-single__content">
                            <div className="news-single__content__1">
                                <h3>{news.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: news.text }} className="news-text" />
                            </div>
                            <div className="news-single__content__2">
                                <Button 
                                    Text="En savoir plus"
                                    Link={`/news/${news._id}`}
                                />
                            </div>
                        </div>
                    </div>  
                ))}
            </div>
        </main>
    );
}