import React, { useState } from "react";
import Voitures from "../assets/data/voitures.json";
import Button from "../components/Bouton"
import LeftArrow from "../assets/arrow-circle-left-svgrepo-com.png";
import RightArrow from "../assets/arrow-circle-right-svgrepo-com.png";

export default function Carrousel(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const selectedCar = Voitures[currentIndex];
    const prevHandler = () => {
        currentIndex === 0 ? setCurrentIndex(Voitures.length - 1) : setCurrentIndex(currentIndex - 1)
    };
    const nextHandler = () => {
        currentIndex === Voitures.length - 1 ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1)
    };
    return (
        <section className="carrousel">
                <div className="car__image__container">
                    <img className="car__image" src={selectedCar.image} alt={selectedCar.titre}></img>
                    <h2>{selectedCar.titre}</h2>
                </div>
                <div className="car__description">
                    <div className="car__description__1">
                        <p>{selectedCar.description}</p>
                        <div className="car__description__buttons">
                            <Button 
                                Text="Découvrir"
                                Link={selectedCar.essai}
                                />
                            <Button 
                                Text="Configurer mon Alpine"
                                Link={selectedCar.configurez}
                                />
                        </div>
                    </div>
                    <div className="car__description__2">
                        <div className="carrousel__arrows">
                            <img src={LeftArrow} onClick={prevHandler} alt="Flèche gauche"></img>
                            <span>{selectedCar.titre}</span>
                            <img src={RightArrow} onClick={nextHandler} alt="Flèche droite"></img>
                        </div>
                    </div>
                </div>
        </section>
    );
}