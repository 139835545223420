export default function Car({ modele, version, kilometrage, prix, photo }) {
    const apiUrl = process.env.NODE_ENV === 'production'
        ? '/vehicleImages/'  // En production
        : 'http://localhost:5000/vehicleImages/';  // En développement

    return (
        <div className="car-card">
            <img 
                src={`${apiUrl}${photo}`} 
                alt={modele} 
                className="car-card__image"
                onError={(e) => {
                    e.target.src = '/placeholder.jpg'; // Image par défaut en cas d'erreur
                    console.error('Erreur de chargement de l\'image:', photo);
                }}
            />
            <div className="car-card__content">
                <h2 className="car-card__title">{modele}</h2>
                <span className="car-card__price">{prix} €</span>
                <p className="car-card__version">{version}</p>
                <span className="car-card__mileage">{kilometrage} km</span>
            </div>
        </div>
    );
}