import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Login from '../components/Login';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Bouton';

export default function Admin() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);
  const [date, setDate] = useState('');
  
  const navigate = useNavigate();

  useEffect(() => {
    const authStatus = localStorage.getItem('isAdminAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetch('https://alpine-larochelle.com/api/news')
        .then((response) => response.json())
        .then((data) => setNewsList(data))
        .catch((err) => console.log('Error loading news:', err));
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.removeItem('isAdminAuthenticated');
    setIsAuthenticated(false);
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let validLink = link;
    if (!/^https?:\/\//i.test(link)) {
      validLink = `http://${link}`;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    formData.append('link', validLink);
    if (image) formData.append('image', image);
    formData.append('date', date);

    const url = editing
      ? `https://alpine-larochelle.com/api/news/${editId}`
      : 'https://alpine-larochelle.com/api/news';

    const method = editing ? 'PUT' : 'POST';

    fetch(url, {
      method,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setNewsList((prevNews) =>
          editing
            ? prevNews.map((item) =>
                item._id === data._id ? { ...item, ...data } : item
              )
            : [...prevNews, data]
        );
        setTitle('');
        setText('');
        setLink('');
        setImage(null);
        setEditing(false);
      })
      .catch((err) => console.log('Error saving news:', err));
  };

  const handleEdit = (news) => {
    setTitle(news.title);
    setText(news.text);
    setLink(news.link);
    setEditing(true);
    setEditId(news._id);
  };

  const handleDelete = (id) => {
    fetch(`https://alpine-larochelle.com/api/news/${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setNewsList(newsList.filter((news) => news._id !== id));
        setShowModal(false);
      })
      .catch((err) => console.log('Error deleting news:', err));
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setNewsToDelete(null);
  };

  const handleConfirmDelete = (newsId) => {
    setShowModal(true);
    setNewsToDelete(newsId);
  };

  if (!isAuthenticated) {
    return <Login onLogin={setIsAuthenticated} />;
  }

  return (
    <div className='admin'>
      <div className="admin__header">
        <Button 
          Text="Déconnexion" 
          Link="#" 
          onClick={handleLogout} 
        />
        <h1>{editing ? 'Modifier une actualité' : 'Ajouter une nouvelle actualité'}</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label>Titre</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Texte</label>
          <Editor
            apiKey='7inxkbdnk1pxuxacmy164kxbff9imcxkui32pt05canei4t3'
            value={text}
            onEditorChange={(content) => setText(content)}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                'emoticons', 'template', 'paste', 'textcolor', 'hr', 'fontsizeselect'
              ],
              toolbar: 'undo redo | fontsizeselect | h1 h2 h3 h4 h5 | ' +
                'bold italic underline strikethrough | forecolor backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist | link image media | ' +
                'removeformat fullscreen | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              branding: false,
              statusbar: true,
              resize: true,
              fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
              style_formats: [
                { title: 'Heading 1', format: 'h1' },
                { title: 'Heading 2', format: 'h2' },
                { title: 'Heading 3', format: 'h3' },
                { title: 'Heading 4', format: 'h4' },
                { title: 'Heading 5', format: 'h5' },
                { title: 'Normal', format: 'p' }
              ]
            }}
          />
        </div>

        <div>
          <label>Lien</label>
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>

        <div>
          <label>Date de l'événement</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div>
          <label>Image</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
          />
        </div>

        <Button 
          Text={editing ? 'Mettre à jour' : 'Ajouter'} 
          Link="#"
          type="submit"
        />
      </form>

      <h2>Liste des actualités</h2>
      <ul>
        {newsList.map((news) => (
          <li className="admin-news" key={news._id}>
            <div className="admin-news__content">
              <h3>{news.title}</h3>
              <p>{news.text}</p>
              <a href={news.link} target="_blank" rel="noopener noreferrer">
                {news.link}
              </a>
              <div className="admin-news__buttons">
                <Button 
                  Text="Éditer" 
                  Link="#" 
                  onClick={() => handleEdit(news)}
                />
                <Button 
                  Text="Supprimer" 
                  Link="#" 
                  onClick={() => handleConfirmDelete(news._id)}
                />
              </div>
            </div>
            <div className="admin-news__image">
              <img src={`https://alpine-larochelle.com/uploads/${news.image}`} alt={news.title} />
            </div>
          </li>
        ))}
      </ul>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Êtes-vous sûr de vouloir supprimer cette actualité ?</h4>
            <div className="modal-buttons">
              <Button 
                Text="Oui, supprimer" 
                Link="#" 
                onClick={() => handleDelete(newsToDelete)}
              />
              <Button 
                Text="Annuler" 
                Link="#" 
                onClick={handleCancelDelete}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
