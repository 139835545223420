import React, { useEffect, useState } from "react";
import LeftArrow from "../assets/arrow-left-white.png";
import RightArrow from "../assets/arrow-right-white.png";
import Button from "./Bouton";


export default function NewsCarrousel() {
    const [news, setNews] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        fetch('https://alpine-larochelle.com/api/news')
        .then((response) => response.json())
        .then((data) => setNews(data))
        .catch((error) => console.error('Error fetching news:', error));
    }, []);

    const nextNews = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
      };
    
      const prevNews = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? news.length - 1 : prevIndex - 1
        );
      };
    
      if (news.length === 0) return <p>Chargement...</p>;
    
      const currentNews = news[currentIndex];

    return (
        <section className="news-carrousel">
            <h2>/ Les dernières actualités de votre alpine store la rochelle</h2>
            <div className="news-carrousel__container">
                <img className="news-carrousel__arrow" src={LeftArrow} onClick={prevNews} alt="Left Arrow"></img>
                <div className="news-carrousel__news">
                    <img src={`https://alpine-larochelle.com/uploads/${currentNews.image}`} alt={currentNews.title} />
                    <div className="news-carrousel__news__content">
                        <div className="news-carrousel__news__content__1">
                            <h3>{currentNews.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: currentNews.text }} className="news-text" />
                        </div>
                        <div className="news-carrousel__news__content__2">
                            <Button 
                                Text="En savoir plus"
                                Link={`/news/${currentNews._id}`}
                                />
                        </div>
                    </div>
                </div>
                <img className="news-carrousel__arrow" src={RightArrow} onClick={nextNews} alt="Left Arrow"></img>
            </div>
        </section>
    );
}